import axiosClient from 'utils/axiosClient';
import api from 'api';

/* Template */
/* get */

const getAirports = async () => {
	const response = await axiosClient.get(api.PRIVATE.GET_AIRPORTS);
	return response.data;
};

const getDestination = async (data?: any) => {
	const response = await axiosClient.get(api.PRIVATE.DESTINATION, {params: data});
	return response.data;
};

const getHotelProviders = async () => {
	const response = await axiosClient.get(api.PRIVATE.HOTEL_PROVIDERS);
	return response.data;
};

const getNationality = async (data?: any) => {
	const response = await axiosClient.get(api.PRIVATE.GET_NATIONALITY, {params: data});
	return response.data;
};

const getCompanySettings = async () => {
	const response = await axiosClient.get(api.PRIVATE.GET_COMPANY_SETTINGS);
	return response.data;
};
const getNotifications = async () => {
	const response = await axiosClient.get(api.PRIVATE.NOTIFICATIONS);
	return response.data;
};
const getSMTPSettings = async () => {
	const response = await axiosClient.get(api.PRIVATE.GET_SMTP_SETTINGS);
	return response.data;
};
const getTicketSettings = async () => {
	const response = await axiosClient.get(api.PRIVATE.GET_TICKET_SETTINGS);
	return response.data;
};

const getTimeLimits = async () => {
	const response = await axiosClient.get(api.PRIVATE.GET_TIME_LIMITS);
	return response.data;
};

const getCurrencyTypes = async () => {
	const response = await axiosClient.get(api.PRIVATE.GET_CURRENCY_TYPES);
	return response.data;
};

const getCurrencySettings = async () => {
	const response = await axiosClient.get(api.PRIVATE.CURRENCY);
	return response.data;
};

const getCurrencyMarginTypes = async () => {
	const response = await axiosClient.get(api.PRIVATE.CURRENCY_MARGIN_TYPES);
	return response.data;
};

const getHomepageSliders = async () => {
	const response = await axiosClient.get(api.PRIVATE.HOMEPAGE_SLIDERS_GET);
	return response.data;
};

const getHomePageSpecialOffers = async () => {
	const response = await axiosClient.get(api.PRIVATE.HOMEPAGGE_SPECIAL_OFFERS);
	return response.data;
};

const getHotelMarkups = async () => {
	const response = await axiosClient.get(api.PRIVATE.HOTEL_MARKUPS);
	return response.data;
};

const getSpecialOffers = async () => {
	const response = await axiosClient.get(api.PRIVATE.SPECIAL_OFFERS);
	return response.data;
};

const getHomePageBanners = async () => {
	const response = await axiosClient.get(api.PRIVATE.HOMEPAGE_BANNERS);
	return response.data;
};

const getOnePageSliders = async () => {
	const response = await axiosClient.get(api.PRIVATE.ONEPAGE_SLIDERS);
	return response.data;
};

/* create */

interface CreateAircraftParams {
	name: string;
}
const createAirports = async (data: CreateAircraftParams) => {
	const response = await axiosClient.post(api.PRIVATE.CREATE_AIRPORT, data);
	return response.data;
};

const createHotelProviders = async (data: CreateAircraftParams) => {
	const response = await axiosClient.post(api.PRIVATE.HOTEL_PROVIDERS, data);
	return response.data;
};

const createRoomCategory = async (data: CreateAircraftParams) => {
	const response = await axiosClient.post(api.PRIVATE.GET_ROOM_CATEGORY, data);
	return response.data;
};

const createNotifications = async (data: any) => {
	const response = await axiosClient.post(api.PRIVATE.NOTIFICATIONS, data);
	return response.data;
};
const createSMTPSettings = async (data: any) => {
	const response = await axiosClient.post(api.PRIVATE.CREATE_SMTP_SETTING, data);
	return response.data;
};

const createTicketSettings = async (data: any) => {
	const response = await axiosClient.post(api.PRIVATE.CREATE_TICKET_SETTING, data);
	return response.data;
};

const createCompanySettings = async (data: any) => {
	const response = await axiosClient.post(api.PRIVATE.CREATE_COMPANY_SETTING, data);
	return response.data;
};

const createTimeLimits = async (data: any) => {
	const response = await axiosClient.post(api.PRIVATE.CREATE_TIME_LIMITS, data);
	return response.data;
};

const createCurrencySettings = async (data: any) => {
	const response = await axiosClient.post(api.PRIVATE.CURRENCY, data);
	return response.data;
};

const createNationality = async (data: any) => {
	const response = await axiosClient.post(api.PRIVATE.CREATE_NATIONALITY, data);
	return response.data;
};
const createHomepageSliders = async (data: any) => {
	const response = await axiosClient.post(api.PRIVATE.HOMEPAGE_SLIDERS, data);
	return response.data;
};

const createHotelMarkups = async (data: any) => {
	const response = await axiosClient.post(api.PRIVATE.HOTEL_MARKUPS, data);
	return response.data;
};

const createHomePageSpecialOffers = async (data: any) => {
	const response = await axiosClient.post(api.PRIVATE.HOMEPAGGE_SPECIAL_OFFERS, data);
	return response.data;
};

const createSpecialOffers = async (data: any) => {
	const response = await axiosClient.post(api.PRIVATE.SPECIAL_OFFERS, data);
	return response.data;
};

const createHomepageBanners = async (data: any) => {
	const response = await axiosClient.post(api.PRIVATE.HOMEPAGE_BANNERS, data);
	return response.data;
};

const createOnePageSliders = async (data: any) => {
	const response = await axiosClient.post(api.PRIVATE.ONEPAGE_SLIDERS, data);
	return response.data;
};

/**
 * Delete
 */

const deleteAirport = async (id: string) => {
	const response = await axiosClient.delete(api.PRIVATE.DELETE_AIRPORT + id);
	return response.data;
};

const deleteHotelProvider = async (id: string) => {
	const response = await axiosClient.delete(api.PRIVATE.DELETE_HOTEL_PROVIDER + id);
	return response.data;
};

const deleteRoomCategory = async (id: string) => {
	const response = await axiosClient.delete(api.PRIVATE.DELETE_ROOM_CATEGORY + id);
	return response.data;
};

const deleteNotification = async (id: string) => {
	const response = await axiosClient.delete(api.PRIVATE.NOTIFICATIONS + id);
	return response.data;
};

const deleteNationality = async (id: string) => {
	const response = await axiosClient.delete(api.PRIVATE.DELETE_NATIONALITY + id);
	return response.data;
};

const deleteCurrencySettings = async (id: string) => {
	const response = await axiosClient.delete(api.PRIVATE.CURRENCY + id);
	return response.data;
};

const deleteHomepageSliders = async (id: string) => {
	const response = await axiosClient.delete(api.PRIVATE.HOMEPAGE_SLIDERS + id);
	return response.data;
};

const deleteHotelMarkups = async (id: string) => {
	const response = await axiosClient.delete(api.PRIVATE.HOTEL_MARKUPS + id);
	return response.data;
};

const deleteHomepageBanners = async (id: string) => {
	const response = await axiosClient.delete(api.PRIVATE.HOMEPAGE_BANNERS + id);
	return response.data;
};

const deleteOnePageSliders = async (id: string) => {
	const response = await axiosClient.delete(api.PRIVATE.ONEPAGE_SLIDERS + id);
	return response.data;
};

const deleteHomePageSpecialOffers = async (id: string) => {
	const response = await axiosClient.delete(api.PRIVATE.HOMEPAGGE_SPECIAL_OFFERS + id);
	return response.data;
};

const deleteSpecialOffers = async (id: string) => {
	const response = await axiosClient.delete(api.PRIVATE.SPECIAL_OFFERS + id);
	return response.data;
};

const deleteProviders = async (id: string) => {
	const response = await axiosClient.delete(api.PRIVATE.DELETE_PROVIDERS + id);
	return response.data;
};

/**
 * Update
 */

const updateTicketSettings = async (data: any, id: string) => {
	const response = await axiosClient.put(api.PRIVATE.UPDATE_TICKET_SETTING + id, data);
	return response.data;
};

const updateAirports = async (data: any, id: string) => {
	const response = await axiosClient.put(api.PRIVATE.UPDATE_AIRPORTS + id, data);
	return response.data;
};
const updateDestination = async (data: any, id: string) => {
	const response = await axiosClient.put(api.PRIVATE.DESTINATION + id, data);
	return response.data;
};

const updateProviders = async (data: any, id: string) => {
	const response = await axiosClient.put(api.PRIVATE.UPDATE_PROVIDERS + id, data);
	return response.data;
};

const updateCompanySettings = async (data: any) => {
	const response = await axiosClient.put(api.PRIVATE.UPDATE_COMPANY_SETTINGS, data);
	return response.data;
};

const updateSMTPSettings = async (data: any) => {
	const response = await axiosClient.put(api.PRIVATE.UPDATE_SMTP_SETTING, data);
	return response.data;
};

const updateNotifications = async (data: any, id: string) => {
	const response = await axiosClient.put(api.PRIVATE.NOTIFICATIONS + id, data);
	return response.data;
};

const updateTimeLimits = async (data: any, id: string) => {
	const response = await axiosClient.put(api.PRIVATE.UPDATE_TIME_LIMITS + id, data);
	return response.data;
};
const updateCurrencySettings = async (data: any, id: string) => {
	const response = await axiosClient.put(api.PRIVATE.CURRENCY + id, data);
	return response.data;
};

const updateHomepageSliders = async (data: any, id: string) => {
	const response = await axiosClient.put(api.PRIVATE.HOMEPAGE_SLIDERS + id, data);
	return response.data;
};

const updateHotelMarkups = async (data: any, id: string) => {
	const response = await axiosClient.put(api.PRIVATE.HOTEL_MARKUPS + id, data);
	return response.data;
};

const updateHomepageBanners = async (data: any, id: string) => {
	const response = await axiosClient.put(api.PRIVATE.HOMEPAGE_BANNERS + id, data);
	return response.data;
};

const updateOnePageSliders = async (data: any, id: string) => {
	const response = await axiosClient.put(api.PRIVATE.ONEPAGE_SLIDERS + id, data);
	return response.data;
};

const updateHomePageSpecialOffers = async (data: any, id: string) => {
	const response = await axiosClient.put(api.PRIVATE.HOMEPAGGE_SPECIAL_OFFERS + id, data);
	return response.data;
};

const updateSpecialOffers = async (data: any, id: string) => {
	const response = await axiosClient.put(api.PRIVATE.SPECIAL_OFFERS + id, data);
	return response.data;
};

const updateNationality = async (data: any, id: string) => {
	const response = await axiosClient.put(api.PRIVATE.UPDATE_NATIONALITY + id, data);
	return response.data;
};

const service = {
	getCompanySettings,
	getTicketSettings,
	getSMTPSettings,
	getNotifications,
	getHotelProviders,
	getAirports,
	getNationality,
	getTimeLimits,
	getCurrencySettings,
	getCurrencyMarginTypes,
	getCurrencyTypes,
	getHomepageSliders,
	getSpecialOffers,
	getHomePageSpecialOffers,
	getHomePageBanners,
	getOnePageSliders,
	getHotelMarkups,
	getDestination,

	createAirports,
	createHotelProviders,
	createRoomCategory,
	createTicketSettings,
	createNotifications,
	createCompanySettings,
	createSMTPSettings,
	createNationality,
	createTimeLimits,
	createCurrencySettings,
	createHomepageSliders,
	createSpecialOffers,
	createHomePageSpecialOffers,
	createHomepageBanners,
	createOnePageSliders,
	createHotelMarkups,

	deleteAirport,
	deleteHotelProvider,
	deleteRoomCategory,
	deleteNotification,
	deleteNationality,
	deleteCurrencySettings,
	deleteHomepageSliders,
	deleteSpecialOffers,
	deleteHomePageSpecialOffers,
	deleteHomepageBanners,
	deleteOnePageSliders,
	deleteProviders,
	deleteHotelMarkups,

	updateTicketSettings,
	updateAirports,
	updateCompanySettings,
	updateSMTPSettings,
	updateNotifications,
	updateTimeLimits,
	updateNationality,
	updateCurrencySettings,
	updateHomepageSliders,
	updateSpecialOffers,
	updateHomePageSpecialOffers,
	updateHomepageBanners,
	updateOnePageSliders,
	updateProviders,
	updateHotelMarkups,
	updateDestination
};

export default service;
