import axiosClient from 'utils/axiosClient';
import api from 'api';

/**
 * Get
 */

interface HistoryProps {
	start: string;
	end: string;
}

const getHistory = async (data: HistoryProps) => {
	const response = await axiosClient.post(api.PRIVATE.GET_HISTORY, data);
	return response.data;
};
const getHistoryById = async (id: string) => {
	const response = await axiosClient.get(api.PRIVATE.GET_HISTORY_BY_ID + id);
	return response.data;
};
const getActions = async () => {
	const response = await axiosClient.get(api.PRIVATE.GET_ACTIONS);
	return response.data;
};

const service = {
	getHistory,
	getActions,
	getHistoryById
};

export default service;
