import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AuthProvider from 'context/AuthContext';
import ProfileProvider from 'context/ProfileContext';
import {ThemeProvider, createTheme} from '@material-ui/core/styles';

const theme = createTheme({
	palette: {
		primary: {
			main: '#3f489d'
		},
		secondary: {
			main: '#5169F3'
		},
		error: {
			main: '#FF3333'
		}
	},
	typography: {
		fontFamily: ['"Trebuchet MS", "Helvetica Neue", sans-serif'].join(',')
	}
});

ReactDOM.render(
	<AuthProvider>
		<ProfileProvider>
			<Router>
				<ThemeProvider theme={theme}>
					<App />
				</ThemeProvider>
			</Router>
		</ProfileProvider>
	</AuthProvider>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
