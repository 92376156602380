// API with token

/**
 * Search
 */
const SEARCH_HOTEL = '/api/juniper/searchHotels';
const SEARCH_TOUR = '/api/serviceSearches/tour';
const SEARCH_TRANSFER = '/api/serviceSearches/transfer';
const SEARCH_VISA = '/api/serviceSearches/visa';
const PRE_ORDER = '/api/juniper/bookingRule';
const CANCEl_PRE_ORDER = '/api/hotelOrders/cancel-pre-order/';
const CREATE_ORDER = '/api/orders/order';
const CREATE_ORDER_HOTEL = '/api/juniper/booking';
const CREATE_SERVICE_ORDER = '/api/hotelOrders/serviceOrder';

/**
 * Reservations
 */

const GET_ORDERS_HOTEL = '/api/juniper/orders/';
const GET_ORDERS_HOTEL1 = '/api/hotelOrders';
const GET_SERVICE_ORDERS = '/api/tourOrders';
const UPDATE_ORDER_HOTEL = '/api/juniper/';
const CENCEL_BOOKING_HOTEL = '/api/juniper/cancelBooking';
const PRE_CENCEL_BOOKING_HOTEL = '/api/juniper/preCancel';
const BOOKING_TO_TICKETING_HOTEL = '/api/hotelOrders/ticketing/';
const REFUND_HOTEL = '/api/hotelOrders/refund/';
const CREATE_ORDER_SERVICE = '/api/tourOrders';
const GET_HOTEL_ORDER_BY_ID = '/api/juniper/order/';
const GET_SERVICE_ORDER_BY_ID = '/api/tourOrders/';

const REFUND_SERVICE = '/api/tourOrders/refund/';
const CENCEL_BOOKING_SERVICE = '/api/tourOrders/cancel/';
const BOOKING_TO_TICKETING_SERVICE = '/api/tourOrders/confirm/';
const UPDATE_ORDER_SERVICE = '/api/tourOrders/';

/**
 * Profile
 */
const PROFILE_API = '/api/profile';

/**
 * Agents
 */
const GET_AGENTS = '/api/users/get-users/';
const GET_AGENTS_BY_ID = '/api/users/get-user/';
const GET_BALANCE_HISTORY = '/api/users/balance-history/';
const UPDATE_AGENT = '/api/users/update/';
const ADD_BALANCE = '/api/users/balance/transfer/';
const USE_BALANCE = '/api/users/use-balance/';
const CHANGE_USER_PASSWORD = '/api/users/change-password/';

/**
 * Services
 */
const GET_HISTORY = '/api/logs/get';
const GET_HISTORY_BY_ID = '/api/logs/';
const GET_ACTIONS = '/api/logs/actions';

/**
 * Hotels
 */
const HOTELS = '/api/juniper/citySearch';
const UPDATE_HOTEL = '/api/hotels/';
const GET_MARKUPS = '/api/hotels/B2B';
const HOTEL_PROVIDERS = '/api/hotelProviders';
const GET_HOTEL_CATEGORY = '/api/hotels/category';
const GET_HOTEL_ROOM_MEAL = '/api/roomsCategory/mealTypes';
const GET_ROOM_CATEGORY = '/api/roomsCategory';
const GET_HOTEL_BY_JP = '/api/hotels/code/';
const GET_DAILY_INFO = '/api/juniper/checkAvailability';
const ROOMS = 'api/rooms';

/**
 * History
 */
const SERVICES = '/api/services';

/**
 * Reports
 */

const REPORTS = '/api/reports/orders-full-data';

/**
 * Settings
 */

const GET_AIRPORTS = '/api/airport';
const GET_COMPANY_SETTINGS = '/api/companySettings/';
const GET_TICKET_SETTINGS = '/api/ticketSettings';
const GET_SMTP_SETTINGS = '/api/SMTPsettings';
const GET_TIME_LIMITS = '/api/timeLimits/';
const GET_HOTELS = '/api/juniper/hotels';
const GET_NATIONALITY = '/api/nationality/';
const GET_CURRENCY_TYPES = '/api/currencySettings/currencyCodes';
const NOTIFICATIONS = '/api/notifications/';
const CURRENCY = '/api/currencySettings/';
const CURRENCY_MARGIN_TYPES = '/api/currencySettings/MarginTypes';
const HOMEPAGE_SLIDERS = '/api/homepageSlider/';
const HOMEPAGE_SLIDERS_GET = '/filters/homepageSlider/';
const HOMEPAGE_BANNERS = '/api/homepageBanners/';
const ONEPAGE_SLIDERS = '/api/onepageSliders/';
const HOMEPAGGE_SPECIAL_OFFERS = '/api/specialOffers/';
const SPECIAL_OFFERS = '/api/loginPopup/';
const HOTEL_MARKUPS = '/api/hotelMarkups/';
const DESTINATION = '/api/destinations/';

const CREATE_AIRPORT = '/api/airport';
const CREATE_COMPANY_INFO = '/api/company-info';
const CREATE_COMPANY_SETTING = '/api/companySettings';
const CREATE_TICKET_SETTING = '/api/ticketSettings';
const CREATE_SMTP_SETTING = '/api/SMTPsettings/';
const CREATE_TIME_LIMITS = '/api/timeLimits';
const CREATE_NATIONALITY = '/api/nationality';

const UPDATE_TICKET_SETTING = '/api/ticketSettings/';
const UPDATE_COMPANY_SETTINGS = '/api/companySettings/edit';
const UPDATE_SMTP_SETTING = '/api/SMTPsettings/edit';
const UPDATE_TIME_LIMITS = '/api/timeLimits/';
const UPDATE_AIRPORTS = '/api/airport/';
const UPDATE_PROVIDERS = '/api/hotelProviders/';
const UPDATE_NATIONALITY = '/api/nationality/';

const DELETE_AIRPORT = '/api/airport/';
const DELETE_HOTEL_PROVIDER = '/api/hotelProviders/';
const DELETE_ROOM_CATEGORY = '/api/roomsCategory/';
const DELETE_PROVIDERS = '/api/hotelProviders/';
const DELETE_NATIONALITY = '/api/nationality/';

const RELOAD_BOOKING = '/api/juniper/readBooking';

const PRIVATE_API = {
	SEARCH_HOTEL,
	SEARCH_TRANSFER,
	SEARCH_VISA,
	SEARCH_TOUR,
	PRE_ORDER,
	CENCEL_BOOKING_HOTEL,
	BOOKING_TO_TICKETING_HOTEL,
	REFUND_HOTEL,
	PROFILE_API,
	ADD_BALANCE,
	USE_BALANCE,
	HOTEL_PROVIDERS,
	HOTELS,
	ROOMS,
	SERVICES,
	REPORTS,
	NOTIFICATIONS,
	CURRENCY,
	HOMEPAGE_SLIDERS,
	HOMEPAGE_SLIDERS_GET,
	SPECIAL_OFFERS,
	HOMEPAGGE_SPECIAL_OFFERS,
	HOTEL_MARKUPS,
	UPDATE_HOTEL,
	HOMEPAGE_BANNERS,
	ONEPAGE_SLIDERS,
	CHANGE_USER_PASSWORD,
	CREATE_SERVICE_ORDER,
	GET_HISTORY_BY_ID,
	GET_HOTEL_BY_JP,
	GET_DAILY_INFO,
	GET_ACTIONS,
	GET_HOTELS,
	CREATE_ORDER_SERVICE,
	REFUND_SERVICE,
	CENCEL_BOOKING_SERVICE,
	BOOKING_TO_TICKETING_SERVICE,
	RELOAD_BOOKING,
	UPDATE_ORDER_SERVICE,
	PRE_CENCEL_BOOKING_HOTEL,
	CANCEl_PRE_ORDER,

	UPDATE_ORDER_HOTEL,
	UPDATE_AGENT,
	UPDATE_TICKET_SETTING,
	UPDATE_SMTP_SETTING,
	UPDATE_COMPANY_SETTINGS,
	UPDATE_NATIONALITY,
	UPDATE_TIME_LIMITS,
	UPDATE_AIRPORTS,
	UPDATE_PROVIDERS,

	GET_AIRPORTS,
	DESTINATION,
	GET_ORDERS_HOTEL,
	GET_SERVICE_ORDERS,
	GET_AGENTS,
	GET_AGENTS_BY_ID,
	GET_HISTORY,
	GET_HOTEL_CATEGORY,
	GET_MARKUPS,
	GET_HOTEL_ROOM_MEAL,
	GET_ROOM_CATEGORY,
	GET_COMPANY_SETTINGS,
	GET_TICKET_SETTINGS,
	GET_SMTP_SETTINGS,
	GET_TIME_LIMITS,
	GET_BALANCE_HISTORY,
	GET_NATIONALITY,
	GET_CURRENCY_TYPES,
	GET_SERVICE_ORDER_BY_ID,
	GET_ORDERS_HOTEL1,
	GET_HOTEL_ORDER_BY_ID,
	CURRENCY_MARGIN_TYPES,

	CREATE_AIRPORT,
	CREATE_COMPANY_INFO,
	CREATE_COMPANY_SETTING,
	CREATE_TICKET_SETTING,
	CREATE_SMTP_SETTING,
	CREATE_TIME_LIMITS,
	CREATE_ORDER,
	CREATE_ORDER_HOTEL,
	CREATE_NATIONALITY,

	DELETE_ROOM_CATEGORY,
	DELETE_AIRPORT,
	DELETE_HOTEL_PROVIDER,
	DELETE_NATIONALITY,
	DELETE_PROVIDERS
};

export default PRIVATE_API;
