import {useCallback, useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Button, IconButton, makeStyles} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import services from 'services';
import {useHistory} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
	dialog: {
		'& .MuiPaper-root': {
			background: 'rgba(0,154,227,.4)',
			color: 'white',
			minWidth: '50%'
		}
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: 'white'
	},
	image: {
		width: '100%',
		maxHeight: 400
	},
	seeMore: {
		textDecoration: 'unset',
		color: 'white',
		textTransform: 'unset'
	}
}));

const LoginPopup = () => {
	const [open, setOpen] = useState(true);
	const classes = useStyles();
	const [data, setData] = useState([]);
	const history = useHistory();

	const handleClose = () => {
		setOpen(false);
		localStorage.setItem('isLogedIn', 'false');
	};

	const getPopupData = useCallback(() => {
		services.PRIVATE_SERVICES.SETTINGS.getSpecialOffers().then(res => {
			setData(res.data);
		});
	}, []);

	useEffect(() => {
		getPopupData();
	}, []);

	const filteredData = data.filter((el: any) => el.status !== 0 && el.isPopUp !== 'no');

	const item: any =
		filteredData.length > 0 && filteredData[Math.floor(Math.random() * filteredData.length)];

	return (
		item && (
			<div onClick={handleClose}>
				<Dialog
					maxWidth="md"
					open={open}
					onClose={handleClose}
					aria-labelledby="form-dialog-title"
					className={classes.dialog}>
					<DialogTitle id="form-dialog-title"></DialogTitle>
					<DialogActions>
						<IconButton aria-label="close" className={classes.closeButton}>
							<CloseIcon />
						</IconButton>
					</DialogActions>
					{/* {item && ( */}
					<DialogContent>
						<img
							src={process.env.REACT_APP_API_URL + item.picture}
							alt=""
							className={classes.image}
						/>
						<DialogContentText style={{color: 'white', marginTop: 10}}>
							{item.shortDes}
						</DialogContentText>
						<div style={{display: 'flex', justifyContent: 'end'}}>
							<Button
								className={classes.seeMore}
								onClick={() => {
									setOpen(false);
									history.push('/specialOffers');
								}}>
								See More
							</Button>
						</div>
					</DialogContent>
					{/* )} */}
				</Dialog>
			</div>
		)
	);
};

export default LoginPopup;
