import {useContext} from 'react';
import {
	AppBar,
	Toolbar,
	List,
	ListItem,
	ListItemText,
	Button,
	Link,
	makeStyles,
	useTheme,
	useMediaQuery
} from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import EuroIcon from '@material-ui/icons/Euro';
import FacebookIcon from 'assets/icons/facebook.png';
import InstagramIcon from 'assets/icons/instagram.png';
import PopoverComponent from 'components/PopoverComponent';
import {AuthContext} from 'context/AuthContext';
import {ProfileContext} from 'context/ProfileContext';
import {currencyFormatterDecimal} from 'helpers/currency.helper';
import {useHistory, Link as RouterLink} from 'react-router-dom';
import ROLES from 'constants/role.contstants';
import profile from 'assets/icons/profile.png';
import logOut from 'assets/icons/logOut.png';
import SettingsIcon from '@material-ui/icons/Settings';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';

export const social = [
	{
		icon: FacebookIcon,
		url: 'https://www.facebook.com/kandatravelclub/',
		name: 'face'
	},
	{
		icon: InstagramIcon,
		url: 'https://www.instagram.com/accounts/login/?next=/kandaclub/'
	}
	// {
	// 	icon: GoogleIcon,
	// 	url: ''
	// },
	// {
	// 	icon: LinkedinIcon,
	// 	url: ''
	// },
	// {
	// 	icon: VkontakteIcon,
	// 	url: ''
	// },
	// {
	// 	icon: YoutubeIcon,
	// 	url: ''
	// },
	// {
	// 	icon: TwitterIcon,
	// 	url: ''
	// },
	// {
	// 	icon: PinterestIcon,
	// 	url: ''
	// },
	// {
	// 	icon: TripadvisorIcon,
	// 	url: 'https://www.tripadvisor.com/Attraction_Review-g293932-d15274945-Reviews-Hot_Tours_Armenia-Yerevan.html'
	// }
];

export const Social = () => {
	const classes = useStyles();
	const localSocial = [...social];
	return (
		<>
			{localSocial.map((el, i: number) => (
				<Link
					key={i}
					href={el.url}
					underline="none"
					color="inherit"
					target="_blank"
					style={{zIndex: 0}}>
					<div className={classes.socialLinks}>
						<img src={el.icon} alt={'icon'} width={el.name ? '50%' : '65%'} />
					</div>
				</Link>
			))}
		</>
	);
};

const useStyles = makeStyles(theme => ({
	container: {
		boxShadow: 'unset',
		color: 'black',
		backgroundColor: '#3f489d',
		width: 'max-content',
		zIndex: 0,
		[theme.breakpoints.down('sm')]: {
			display: 'none'
		}
	},
	toolbar: {
		display: 'flex',
		justifyContent: 'space-between',
		// paddingRight: 40,
		padding: '0px'
	},
	balance: {
		display: 'flex',
		fontSize: 16,
		fontWeight: 500,
		marginRight: 10
	},
	rightBlock: {
		display: 'flex',
		alignItems: 'center',
		color: '#fff',
		[theme.breakpoints.down(1200)]: {
			display: 'none'
		},
		borderRadius: 15,
		// backgroundColor: 'rgba(255,255,255,.09)',
		padding: '10px 0 10px 20px'
	},
	menuItem: {
		textTransform: 'uppercase',
		background: theme.palette.primary.main,
		display: 'flex',
		justifyContent: 'center',
		height: 'fit-content',
		padding: '10px 30px',
		'&:hover': {
			background: theme.palette.primary.light
		}
	},
	list: {
		textTransform: 'uppercase',
		fontWeight: 500,
		fontSize: 18,
		color: '#7D8494'
	},
	carss: {
		height: 50,
		width: 50,
		backgroundColor: '#fff',
		borderRadius: '50%',
		boxShadow: '0 4px 8px 4px rgb(0 0 0 / 20%)',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginRight: 10
	},
	socialLinks: {
		height: 45,
		width: 45,
		marginBottom: 20,
		zIndex: 1,
		backgroundColor: '#fff',
		borderRadius: '50%',
		boxShadow: '0 4px 8px 4px rgb(0 0 0 / 15%)',
		marginRight: 12,
		position: 'relative',
		transition: '0.3s',
		'& img': {
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			margin: 'auto'
		},
		'&:hover': {
			boxShadow: '0 5px 10px 5px rgba(0,0,0,0.2)',
			cursor: 'pointer'
		}
		/* [theme.breakpoints.down('sm')]: {
			marginBottom: 15,
			marginRight: '10px!important'
		},
		[theme.breakpoints.down('md')]: {
			marginBottom: 15,
			width: '95%'
			// marginRight: 10
		} */
	}
}));

const AppBarComponent = () => {
	const classes = useStyles();
	const history = useHistory();
	const {dispatch: authDispatch} = useContext(AuthContext);
	const {state: profileState} = useContext(ProfileContext);
	const userInfo = profileState.companyName + ' - ' + profileState.contactNameSurname;

	const handleLogout = () => {
		authDispatch({
			type: 'LOGOUT'
		});
	};

	return (
		<AppBar color="primary" position="static" className={classes.container}>
			<Toolbar className={classes.toolbar} variant="dense">
				<div className={classes.rightBlock}>
					<div className={classes.balance}>
						{/* <img src={AMD} alt="amd" height="16" style={{margin: '2px 10px 0 15px'}} />{' '} */}
						{currencyFormatterDecimal(profileState.balance)} $
					</div>
					{/* <span>|</span> */}
					{/* <Button component={RouterLink} to={'/currency'} color="inherit">
						<div
							style={{
								display: 'flex',
								flexDirection: useMediaQuery(theme.breakpoints.down('md')) ? 'column' : 'row',
								justifyContent: 'center',
								alignItems: 'center',
								width: 'fit-content',
								height: '100%',
								fontSize: 16
							}}
							color="inherit">
							<div style={{display: 'flex', alignItems: 'center', marginRight: 10}}>
								<AttachMoneyIcon fontSize="small" />
								{profileState.usdRate}
							</div>
							<div style={{display: 'flex', alignItems: 'center', marginRight: 10}}>
								<EuroIcon fontSize="small" style={{marginRight: 3}} />
								{profileState.eurRate}
							</div>
						</div>
					</Button> */}
					<span>|</span>
					<PopoverComponent title={userInfo} icon={<PersonOutlineIcon />}>
						<List component="nav" className={classes.list}>
							{!(profileState.role !== ROLES.ADMIN) && (
								<ListItem button onClick={() => history.push(`/settings`)}>
									<SettingsIcon
										style={{
											marginRight: 15
										}}
									/>
									<ListItemText primary="Settings" />
								</ListItem>
							)}
							<ListItem button onClick={() => history.push(`/profile`)}>
								<img src={profile} alt="profile icon" style={{marginRight: 15}} />
								<ListItemText primary="My Profile" />
							</ListItem>
							<ListItem button onClick={handleLogout}>
								<img src={logOut} alt="LogOut icon" style={{marginRight: 15}} />
								<ListItemText primary="Log out" />
							</ListItem>
						</List>
					</PopoverComponent>
				</div>
			</Toolbar>
		</AppBar>
	);
};

export default AppBarComponent;
