import axiosClient from 'utils/axiosClient';
import api from 'api';

/**
 * Get
 */

const getHotelReports = async (data?: any) => {
	const response = await axiosClient.post(api.PRIVATE.REPORTS, data);
	return response.data.result;
};

const service = {
	getHotelReports
};

export default service;
