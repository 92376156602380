import React from 'react';
import type {RouteProps} from 'react-router-dom';
import ROLES from 'constants/role.contstants';

interface PrivateRouteProps extends RouteProps {
	name: string;
	role: string[];
	icon?: React.ReactElement;
	navBar?: boolean;
	disable?: boolean;
	disableForRoles?: string[];
	subMenu?: PrivateRouteProps[];
}

export const privateRoutes: Array<PrivateRouteProps> = [
	{
		path: '/search',
		name: 'Search',
		component: React.lazy(() => import('./pages/privatePages/Search')),
		role: [ROLES.ADMIN, ROLES.TRUSTED_USER, ROLES.STANDARD_USER],
		navBar: true
	},
	{
		path: '/checkout',
		name: 'Checkout',
		component: React.lazy(() => import('./pages/privatePages/Checkout')),
		role: [ROLES.ADMIN, ROLES.TRUSTED_USER, ROLES.STANDARD_USER]
	},
	{
		path: '/reservation',
		name: 'Reservations',
		navBar: true,
		role: [ROLES.ADMIN, ROLES.TRUSTED_USER, ROLES.STANDARD_USER],
		exact: true,
		// disable: true,
		component: React.lazy(() => import('./pages/privatePages/Reservations/Hotels'))
		// subMenu: [
		// 	{
		// 		path: '/reservation/hotels',
		// 		name: 'Hotels',
		// 		exact: true,
		// 		component: React.lazy(() => import('./pages/privatePages/Reservations/Hotels')),
		// 		role: [ROLES.ADMIN, ROLES.TRUSTED_USER, ROLES.STANDARD_USER]
		// 	},
		// 	{
		// 		path: '/reservation/services',
		// 		name: 'Services',
		// 		exact: true,
		// 		component: React.lazy(() => import('./pages/privatePages/Reservations/Services')),
		// 		role: [ROLES.ADMIN, ROLES.TRUSTED_USER, ROLES.STANDARD_USER]
		// 	}
		// ]
	},
	{
		path: '/addReservation',
		name: 'Add Reservation',
		role: [ROLES.ADMIN],
		component: React.lazy(() => import('./pages/privatePages/Reservations/AddReservation'))
	},
	{
		path: '/hotels',
		name: 'Hotels',
		component: React.lazy(() => import('./pages/privatePages/Hotels')),
		role: [ROLES.ADMIN],
		navBar: true,
		exact: true
	},
	{
		path: '/hotels/room_type/:id',
		name: 'Room Type',
		component: React.lazy(() => import('./pages/privatePages/Rooms')),
		role: [ROLES.ADMIN]
	},
	{
		path: '/hotelsSearch/:id',
		name: 'Hotel Description',
		component: React.lazy(() => import('./pages/privatePages/HotelDescription')),
		role: [ROLES.ADMIN, ROLES.TRUSTED_USER, ROLES.STANDARD_USER]
	},
	{
		path: '/hotelsSearchSingle/:id',
		name: 'Hotel Description',
		component: React.lazy(() => import('./pages/privatePages/HotelDescription/HotelSinglePage')),
		role: [ROLES.ADMIN, ROLES.TRUSTED_USER, ROLES.STANDARD_USER]
	},
	// {
	// 	path: '/services',
	// 	name: 'Services',
	// 	component: React.lazy(() => import('./pages/privatePages/Services')),
	// 	role: [ROLES.ADMIN],
	// 	navBar: true
	// },
	{
		path: '/agents',
		name: 'Agents',
		component: React.lazy(() => import('./pages/privatePages/Agents')),
		exact: true,
		role: [ROLES.ADMIN],
		navBar: true
	},
	{
		path: '/agents/:id',
		name: 'Agent Info',
		component: React.lazy(() => import('./pages/privatePages/AgentBalance')),
		role: [ROLES.ADMIN]
	},
	{
		path: '/specialOffers',
		name: 'Special Offers',
		component: React.lazy(() => import('./pages/privatePages/SpecialOffers')),
		role: [ROLES.ADMIN, ROLES.TRUSTED_USER, ROLES.STANDARD_USER],
		navBar: true
	},
	{
		path: '/history',
		name: 'History',
		component: React.lazy(() => import('./pages/privatePages/History')),
		role: [ROLES.ADMIN, ROLES.TRUSTED_USER, ROLES.STANDARD_USER],
		navBar: true
	},
	{
		path: '/report',
		name: 'Reports',
		role: [ROLES.ADMIN],
		exact: true,
		// disable: true,
		navBar: true,
		component: React.lazy(() => import('./pages/privatePages/Reports/ReportHotel'))
		// disableForRoles: [ROLES.AGENT, ROLES.ADMIN_HOTELS, ROLES.USER_REPORTS, ROLES.VIEWER]
		// subMenu: [
		// 	{
		// 		path: '/report/hotels',
		// 		name: 'Hotels',
		// 		component: React.lazy(() => import('./pages/privatePages/Reports/ReportHotel')),
		// 		role: [ROLES.ADMIN, ROLES.TRUSTED_USER, ROLES.STANDARD_USER]
		// 	},
		// 	{
		// 		path: '/report/services',
		// 		name: 'Sevices',
		// 		// component: React.lazy(() => import('./pages/privatePages/Reservations/Hotels')),
		// 		disable: true,
		// 		role: [ROLES.ADMIN, ROLES.TRUSTED_USER, ROLES.STANDARD_USER]
		// 	}
		// ]
	},
	{
		path: '/settings',
		name: 'Settings',
		component: React.lazy(() => import('./pages/privatePages/Settings')),
		role: [ROLES.ADMIN]
	},
	{
		path: '/profile',
		name: 'Profile',
		component: React.lazy(() => import('./pages/privatePages/Profile')),
		role: [ROLES.ADMIN, ROLES.TRUSTED_USER, ROLES.STANDARD_USER]
	},
	{
		path: '/currency',
		name: 'Currency',
		component: React.lazy(() => import('./pages/privatePages/Currency')),
		role: [ROLES.ADMIN, ROLES.TRUSTED_USER, ROLES.STANDARD_USER]
	},
	{
		path: '/confirm',
		name: 'Confirm Ticket Status',
		component: React.lazy(() => import('./pages/privatePages/Checkout/ConfirmTicketStatus')),
		role: [ROLES.ADMIN, ROLES.TRUSTED_USER, ROLES.STANDARD_USER]
	},
	{
		path: '/reservation/:path/info/:id',
		name: 'View Information',
		component: React.lazy(() => import('./pages/privatePages/Reservations/ViewInfo')),
		role: [ROLES.ADMIN, ROLES.TRUSTED_USER, ROLES.STANDARD_USER]
	}
];

interface PublicRouteProps extends RouteProps {
	name?: string;
	navBar?: boolean;
}

export const publicRoutes: Array<PublicRouteProps> = [
	{
		path: '/',
		component: React.lazy(() => import('./pages/publicPages/Login')),
		exact: true
	},
	{
		path: '/login',
		component: React.lazy(() => import('./pages/publicPages/Login')),
		exact: true
	},
	{
		path: '/register',
		component: React.lazy(() => import('./pages/publicPages/Register')),
		exact: true
	},
	{
		path: '/reset',
		component: React.lazy(() => import('./pages/publicPages/ResetPassword')),
		exact: true
	},
	{
		path: '/resetVerification',
		component: React.lazy(() => import('./pages/publicPages/ResetPassword/CreateNewPassword')),
		exact: true
	},
	{
		path: '/verification',
		component: React.lazy(() => import('./pages/publicPages/RegisterVerification')),
		exact: true
	},
	{
		path: '/aboutUs',
		name: 'About Us',
		navBar: true,
		component: React.lazy(() => import('./pages/publicPages/About')),
		exact: true
	},
	{
		path: '/whyBookWithUs',
		name: 'Why Book With Us',
		navBar: true,
		component: React.lazy(() => import('./pages/publicPages/WhyBook')),
		exact: true
	},
	{
		path: '/termsAndConditions',
		name: 'Terms and Conditions',
		navBar: true,
		component: React.lazy(() => import('./pages/publicPages/Terms')),
		exact: true
	},
	{
		path: '/contuctUs',
		name: 'Contuct Us',
		navBar: true,
		component: React.lazy(() => import('./pages/publicPages/Contuct')),
		exact: true
	}
];
