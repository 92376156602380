import api from 'api';
import axiosClient from 'utils/axiosClient';

const createTicketPDF = async (id: number) => {
	const response = await axiosClient.get(api.PDF.TICKET_PDF + id, {responseType: 'blob'});
	return response.data;
};

const service = {
	createTicketPDF
};

export default service;
