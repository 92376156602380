import {makeStyles} from '@material-ui/core';
import moment from 'moment';
import packageJson from '../../../package.json';

const useStyles = makeStyles(theme => ({
	footer: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		paddingRight: theme.spacing(5),
		paddingLeft: theme.spacing(5),
		marginTop: 'auto',
		color: '#343548',
		borderTop: '1px solid #DDD',
		display: 'flex',
		marginRight: '0!important',
		marginLeft: '0!important'
	},
	container: {
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
		fontSize: 14,
		[theme.breakpoints.down(700)]: {
			flexDirection: 'column',
			alignItems: 'left'
		},
		[theme.breakpoints.down(403)]: {
			alignItems: 13
		}
	}
}));

const Footer = () => {
	const classes = useStyles();
	return (
		<footer className={classes.footer}>
			<div className={classes.container}>
				<div>Travel Management System v{packageJson.version}</div>

				<div>
					All Rights Reserved {moment().year()}{' '}
					<a href="http://www.smartsoft.am" target="_blank" style={{color: 'black'}}>
						SMARTSOFT LLC
					</a>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
