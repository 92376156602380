import axiosClient from 'utils/axiosClient';
import api from 'api';

interface BookingToTicketingParams {
	paymentType: string;
	partialPayment?: string | undefined;
	partialCurrency?: string | undefined;
}

const getOrdersHotel = async () => {
	const response = await axiosClient.get(api.PRIVATE.GET_ORDERS_HOTEL1);
	return response.data;
};
const getHotelOrderById = async (id: string) => {
	const response = await axiosClient.get(api.PRIVATE.GET_HOTEL_ORDER_BY_ID + id);
	return response.data;
};

const updateOrderHotel = async (data: any, id: string) => {
	const response = await axiosClient.put(api.PRIVATE.UPDATE_ORDER_HOTEL + id, data);
	return response.data;
};
const createServiceOrder = async (data: any) => {
	const response = await axiosClient.post(api.PRIVATE.CREATE_SERVICE_ORDER, data);
	return response.data;
};

const bookingToTicketingHotel = async (data: BookingToTicketingParams, id: string) => {
	const response = await axiosClient.post(api.PRIVATE.BOOKING_TO_TICKETING_HOTEL + id, data);
	return response.data;
};

const cencelBookingHotel = async (data: any) => {
	const response = await axiosClient.post(api.PRIVATE.CENCEL_BOOKING_HOTEL + '/' + data.locator, data);
	return response.data;
};
const preCencelBooking = async (data: any) => {
	const response = await axiosClient.get(api.PRIVATE.PRE_CENCEL_BOOKING_HOTEL, {params: data});
	return response.data;
};

const refundHotel = async (data: {commisionInfo: string}, id: string) => {
	const response = await axiosClient.post(api.PRIVATE.REFUND_HOTEL + id, data);
	return response.data;
};

/* Services */

const getServiceOrders = async () => {
	const response = await axiosClient.get(api.PRIVATE.GET_SERVICE_ORDERS);
	return response.data;
};

const getServiceOrderById = async (id: string) => {
	const response = await axiosClient.get(api.PRIVATE.GET_SERVICE_ORDER_BY_ID + id);
	return response.data;
};

const updateOrderService = async (data: any, id: string) => {
	const response = await axiosClient.put(api.PRIVATE.UPDATE_ORDER_SERVICE + id, data);
	return response.data;
};

const refundService = async (data: {commisionInfo: string}, id: string) => {
	const response = await axiosClient.post(api.PRIVATE.REFUND_SERVICE + id, data);
	return response.data;
};

const cencelBookingService = async (id: string) => {
	const response = await axiosClient.post(api.PRIVATE.CENCEL_BOOKING_SERVICE + id);
	return response.data;
};

const bookingToTicketingService = async (data: BookingToTicketingParams, id: string) => {
	const response = await axiosClient.post(api.PRIVATE.BOOKING_TO_TICKETING_SERVICE + id, data);
	return response.data;
};

const reloadBooking = async (locator: string) => {
	const response = await axiosClient.get(api.PRIVATE.RELOAD_BOOKING, {params: {locator}});
	return response.data;
};

const service = {
	getOrdersHotel,
	getServiceOrders,
	bookingToTicketingService,
	cencelBookingService,
	refundService,
	refundHotel,
	updateOrderService,
	bookingToTicketingHotel,
	getHotelOrderById,
	getServiceOrderById,
	preCencelBooking,
	updateOrderHotel,
	createServiceOrder,
	cencelBookingHotel,
	reloadBooking
};

export default service;
