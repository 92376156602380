import axiosClient from 'utils/axiosClient';
import api from 'api';
import {PassengersInfoTypes} from 'pages/privatePages/Checkout/Checkout';

/**
 * Get
 */

export interface SearchProps {
	travelType?: string;
	passengerTypeAdults?: number;
	passengerTypeChild?: number;
	passengerTypeInfant?: number;
	departureFrom?: string;
	destinationTo?: string;
	departureDate?: string;
	returnDate?: string;
}

export interface SearchHotelProps {
	passengerTypeAdults: number;
	passengerTypeChild?: number;
	passengerTypeInfant?: number;
	country?: string;
	region?: string;
	periodStart: string;
	periodEnd: string;
	category?: string;
	JPDCode?: string;
	JPCode?: string;
}

export interface SearchTourParams {
	country: string;
	city: string;
	tourType: string;
}

const searchHotels = async (data: SearchHotelProps) => {
	const response = await axiosClient.get(api.PRIVATE.SEARCH_HOTEL, {params: data});
	return response.data;
};

const serachTours = async (data: any) => {
	const response = await axiosClient.post(api.PRIVATE.SEARCH_TOUR, data);
	return response.data;
};

const serachTransfer = async (data: any) => {
	const response = await axiosClient.post(api.PRIVATE.SEARCH_TRANSFER, data);
	return response.data;
};

const serachVisa = async (data: any) => {
	const response = await axiosClient.post(api.PRIVATE.SEARCH_VISA, data);
	return response.data;
};

const searchHotelById = async (id: string, data: SearchHotelProps) => {
	const response = await axiosClient.get(api.PRIVATE.SEARCH_HOTEL + '/' + id, {params: data});
	return response.data;
};

export interface PreOrderHotelProps {
	ratePlanCode: string;
	JPCode: string;
	startDate: string;
	endDate: string;
}
export interface CancelPreOrderHotelProps {
	pnr: string;
}

export interface OrderTypeHotel {
	agentId: string | null;
	contactPersonAddres: string;
	contactPersonEmail: string;
	contactPersonFullName: string;
	contactPersonTelephone: string;
	personTypeAdults: number;
	personTypeChild: number;
	personTypeInfant: number;
	personsInfo: PassengersInfoTypes[];
	hotelId: string;
	category: string;
	periodStart: string;
	periodEnd: string;
	details?: string;
}

const preOrderHotel = async (data: PreOrderHotelProps) => {
	const response = await axiosClient.post(api.PRIVATE.PRE_ORDER, data);
	return response.data;
};

const cancelPreOrder = async (pnr: string) => {
	const response = await axiosClient.post(api.PRIVATE.CANCEl_PRE_ORDER + pnr);
	return response.data;
};

const createOrder = async (data: any) => {
	const response = await axiosClient.post(api.PRIVATE.CREATE_ORDER, data);
	return response.data;
};
const createOrderService = async (data: any) => {
	const response = await axiosClient.post(api.PRIVATE.CREATE_ORDER_SERVICE, data);
	return response.data;
};

const createOrderHotel = async (data: OrderTypeHotel) => {
	const response = await axiosClient.post(api.PRIVATE.CREATE_ORDER_HOTEL, data);
	return response.data;
};

const service = {
	preOrderHotel,
	cancelPreOrder,
	createOrder,
	searchHotels,
	serachTransfer,
	serachTours,
	createOrderService,
	serachVisa,
	searchHotelById,
	createOrderHotel
};

export default service;
