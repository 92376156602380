import React, {useState, useContext} from 'react';
import {
	Button,
	Divider,
	Drawer,
	IconButton,
	List,
	ListItem,
	ListItemText,
	makeStyles,
	useMediaQuery,
	useTheme
} from '@material-ui/core';
import ListItemLink from 'components/ListItemLink';
import logoTop from 'assets/logoTop.png';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import {privateRoutes, publicRoutes} from 'router';
import {AuthContext} from 'context/AuthContext';
import {NavLink, Link} from 'react-router-dom';
import ROLES from 'constants/role.contstants';
import AppBarComponent from 'components/AppBar';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import EuroIcon from '@material-ui/icons/Euro';
import {currencyFormatterDecimal} from 'helpers/currency.helper';
import {ProfileContext} from 'context/ProfileContext';

const useStyles = makeStyles(theme => ({
	container: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '8px 24px',
		background: '#3f489d',
		boxShadow: '0 2px 2px -2px grey',
		zIndex: 1,
		marginBottom: 1
	},
	leftBlock: {
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down(1200)]: {
			width: '100%',
			justifyContent: 'space-between',
			paddingLeft: 0
		}
	},
	logo: {
		width: 170,
		marginLeft: 20,
		marginRight: 20,
		[theme.breakpoints.down('sm')]: {
			marginLeft: '0px!important'
		}
	},
	navBar: {
		display: 'flex',
		[theme.breakpoints.down(1200)]: {
			flexDirection: 'column',
			minWidth: theme.breakpoints.values.xs
		}
	},
	menuButton: {
		color: 'white',
		width: 'fit-content',
		alignSelf: 'center'
	},
	menuItem: {
		textTransform: 'uppercase',
		paddingRight: 28,
		'& .MuiTypography-root': {
			fontWeight: 600,
			fontSize: 14
		},
		'& .active': {
			color: theme.palette.secondary.main
		},
		'& .active .MuiListItemIcon-root': {
			color: theme.palette.secondary.main
		}
	},
	closeButton: {
		display: 'flex',
		justifyContent: 'flex-start',
		paddingLeft: 25
	},
	dropdownContent: {
		display: 'none',
		position: 'absolute',
		left: 0,
		top: 40,
		backgroundColor: '#f9f9f9',
		minWidth: '160px',
		boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
		zIndex: 2
	},
	dropdown: {
		position: 'relative',
		display: 'flex',
		textAlign: 'left',
		'&:hover .dropdownContent': {
			display: 'block'
		},
		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0, 0.04)'
		}
	}
}));

const NavBar = () => {
	const classes = useStyles();
	const theme = useTheme();
	const {state: authState, dispatch: authDispatch} = useContext(AuthContext);
	const {state: profileState} = useContext(ProfileContext);

	const filteredRoutes = [
		...privateRoutes.filter(
			el => el.navBar && el.role.includes(profileState.role) && authState.isAuthenticated
		),
		...publicRoutes.filter(el => el.navBar && !authState.isAuthenticated)
	];
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);

	const toggleDrawer = (state: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
		if (
			event.type === 'keydown' &&
			((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
		) {
			return;
		}

		setIsDrawerOpen(state);
	};

	const handleLogout = () => {
		authDispatch({
			type: 'LOGOUT'
		});
	};

	const MenuList = () => (
		<List className={classes.navBar}>
			{filteredRoutes.map((el: any) => {
				let listName = el.name;
				return !el.subMenu ? (
					<ListItemLink
						onClick={toggleDrawer(false)}
						key={el.path}
						to={el.path.indexOf('/:') !== -1 ? el.path.substr(0, el.path.indexOf('/:')) : el.path}
						primary={listName}
						icon={el.icon}
						disable={el.disable}
						disableForRoles={el?.disableForRoles}
						nav={true}
					/>
				) : (
					<div key={el.name} className={classes.dropdown}>
						<ListItemLink
							onClick={toggleDrawer(false)}
							primary={el.name}
							icon={el.icon}
							disable={el.disable}
							to={el.path}
							disableForRoles={el?.disableForRoles}
							nav={true}
						/>

						<div className={`${classes.dropdownContent} dropdownContent`}>
							{el.subMenu
								.filter((el: any) => el.role.includes(profileState.role))
								.map((item: any) => {
									return (
										<ListItemLink
											onClick={toggleDrawer(false)}
											key={item.path}
											to={item.path}
											primary={item.name}
											icon={item.icon}
											disable={item.disable}
											disableForRoles={item?.disableForRoles}
										/>
									);
								})}
						</div>
					</div>
				);
			})}
			{useMediaQuery(theme.breakpoints.down(1200)) ? (
				<>
					<Divider />
					{!(profileState.role !== ROLES.ADMIN) && (
						<ListItemLink to="/settings" primary="Settings" onClick={toggleDrawer(false)} />
					)}
					<ListItemLink to="/profile" primary="Profile" onClick={toggleDrawer(false)} />
					<div className={classes.menuItem} style={{paddingLeft: '16px'}}>
						{currencyFormatterDecimal(profileState.balance)}
					</div>
					{/* <Button component={Link} to={'/currency'} color="inherit">
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'left',
								alignItems: 'left',
								paddingLeft: 5,
								width: '100%',
								height: '100%',
								fontSize: 16
							}}
							color="inherit">
							<div style={{display: 'flex', alignItems: 'center', marginRight: 10}}>
								<AttachMoneyIcon fontSize="small" />
								{profileState.usdRate}
							</div>
							<div style={{display: 'flex', alignItems: 'center', marginRight: 10}}>
								<EuroIcon fontSize="small" style={{marginRight: 3}} />
								{profileState.eurRate}
							</div>
						</div>
					</Button> */}
					<li>
						<ListItem button onClick={handleLogout} className={classes.menuItem}>
							<ListItemText primary="Log out" />
						</ListItem>
					</li>
				</>
			) : null}
		</List>
	);

	return (
		<div className={classes.container}>
			<div className={classes.leftBlock}>
				<NavLink to="/">
					<img src={logoTop} alt={'Logo'} className={classes.logo} />
				</NavLink>

				{useMediaQuery(theme.breakpoints.up(1200)) ? (
					<MenuList />
				) : (
					<>
						<IconButton
							color="primary"
							aria-label="open drawer"
							onClick={toggleDrawer(true)}
							edge="end"
							className={classes.menuButton}
							size="medium">
							<MenuIcon />
						</IconButton>
						<Drawer
							anchor="right"
							variant="temporary"
							open={isDrawerOpen}
							onClose={toggleDrawer(false)}>
							<IconButton
								color="inherit"
								aria-label="close drawer"
								onClick={toggleDrawer(false)}
								edge="start"
								className={classes.closeButton}
								size="medium">
								<CloseIcon style={{color: 'black'}} />
							</IconButton>
							<MenuList />
						</Drawer>
					</>
				)}
			</div>
			{authState.isAuthenticated && <AppBarComponent />}
		</div>
	);
};

export default NavBar;
