import {useContext, useEffect, Suspense, useState, useCallback} from 'react';
import {Container, makeStyles, useMediaQuery, useTheme} from '@material-ui/core';
import {AuthContext} from 'context/AuthContext';
import {ProfileContext} from 'context/ProfileContext';
import {Switch, Route} from 'react-router-dom';
import {privateRoutes, publicRoutes} from './router';
import PrivateRoute from 'components/routes/PrivateRoute';
import PublicRoute from 'components/routes/PublicRoute';
import NavBar from 'components/NavBar';
import Footer from 'components/Footer';
import Loading from 'components/Loading';
import NoMatch from './pages/publicPages/NoMatch';
import PasswordSetting from './pages/publicPages/PasswordSetting';
import services from 'services';
import {useLocation} from 'react-router-dom';
import clsx from 'clsx';
import './App.css';
import ContactUs from 'pages/privatePages/Search/components/ContactUs';
import LoginPopup from 'components/LoginPopup';
import servEr from 'assets/serverError.png';
import AutoSlider from 'components/AutoSlider/AutoSlider';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		minHeight: '100vh'
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		background: '#C5CAEA',
		paddingBottom: 80,
		position: 'relative',
		overflow: 'auto'
	},
	container: {
		paddingTop: 5,
		minHeight: '85vh',
		flexGrow: 1,
		position: 'relative',
		'& .MuiContainer-root': {
			padding: 0
		}
	},
	searchPage: {
		padding: 0
	},
	mainImg: {
		position: 'absolute',
		top: 0,
		left: 0,
		overflow: 'hidden',
		height: '100%',
		width: '100%'
	}
}));

function App() {
	const classes = useStyles();
	const location = useLocation();
	const {state: authState} = useContext(AuthContext);
	const [sliders, setSliders] = useState<any[] | null>([]);
	const {dispatch: profileDispatch} = useContext(ProfileContext);
	const {state: profileState} = useContext(ProfileContext);

	const filteredRoutes = privateRoutes.filter(el => el.role.includes(profileState.role));
	useEffect(() => {
		const userId = authState.userId;
		if (userId) {
			services.PRIVATE_SERVICES.AGENTS.getAgentsByID(userId).then(res => {
				profileDispatch({
					type: 'PROFILE',
					payload: res.result
				});
			});
		}
	}, [profileDispatch, authState.userId]);

	useEffect(() => {
		if (authState.isAuthenticated) {
			const params = {
				startDate: new Date().toISOString().substr(0, 10),
				endDate: new Date().toISOString().substr(0, 10)
			};
			services.PUBLIC_SERVICES.EXCHANGE.getExchangeRange(params).then(res => {
				profileDispatch({
					type: 'EXCHANGE',
					payload: res.result
				});
			});
		}
	}, [profileDispatch, authState.isAuthenticated]);

	const getMainSliders = useCallback(() => {
		services.PRIVATE_SERVICES.SETTINGS.getHomepageSliders()
			.then(res => {
				localStorage.setItem('isError', '0');
				setSliders(
					res.data
						.filter((el: any) => el.status === 1 || el.status === 'Active')
						.sort((a: any, b: any) => (a.order < b.order ? -1 : 1))
						.map((el: any) => {
							return {picture: el.picture, link: el.link};
						})
				);
			})
			.catch(err => {
				localStorage.setItem('isError', '1');
				setSliders(null);
			});
	}, []);

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

	useEffect(() => {
		!authState.isAuthenticated && getMainSliders();
	}, [getMainSliders, authState.isAuthenticated]);

	if (localStorage.getItem('isError') === '0' && authState.isAuthenticated && !profileState.role) {
		return <Loading />;
	}
	if (localStorage.getItem('isError') === '1' || (authState.isAuthenticated && !profileState.usdRate)) {
		return (
			<div
				style={{
					position: 'fixed',
					top: 0,
					left: 0,
					background: '#202229',
					width: '100%',
					height: '100vh',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center'
				}}>
				<img src={servEr} alt="" />
			</div>
		);
	}

	return (
		<div className={classes.root}>
			{authState.isAuthenticated !== undefined ? (
				<>
					<NavBar />
					{localStorage.getItem('isLogedIn') === 'true' && <LoginPopup />}
					<main className={classes.content}>
						{!authState.isAuthenticated &&
							sliders &&
							!isMobile &&
							location.pathname !== '/contuctUs' &&
							location.pathname !== '/aboutUs' &&
							location.pathname !== '/whyBookWithUs' &&
							location.pathname !== '/termsAndConditions' && (
								<div className={classes.mainImg}>
									<AutoSlider
										items={sliders}
										height={location.pathname === '/register' ? '150vh' : '100vh'}
									/>
								</div>
							)}
						<Container
							maxWidth={false}
							className={clsx(
								classes.container,
								location.pathname === '/search' && classes.searchPage
							)}>
							<Suspense fallback={<Loading />}>
								<Switch>
									{publicRoutes.map((route: any, i: number) => (
										<PublicRoute
											key={i}
											{...route}
											authState={authState}
											navBar={route.navBar}
										/>
									))}
									{authState.isAuthenticated
										? filteredRoutes.map((route: any, i: number) =>
												route.subMenu ? (
													route.subMenu.map((route: any, i: number) => (
														<PrivateRoute
															key={i}
															{...route}
															authState={authState}
														/>
													))
												) : (
													<PrivateRoute key={i} {...route} authState={authState} />
												)
										  )
										: privateRoutes.map((route: any, i: number) => (
												<PrivateRoute key={i} {...route} authState={authState} />
										  ))}
									<Route path="/passwordSetting" component={PasswordSetting} />
									<Route component={NoMatch} />
								</Switch>
							</Suspense>
						</Container>
					</main>
					<ContactUs />
					<Footer />
				</>
			) : (
				<Loading />
			)}
		</div>
	);
}

export default App;
