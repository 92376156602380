import {useMemo, forwardRef, useContext} from 'react';
import {ListItem, ListItemIcon, ListItemText, makeStyles} from '@material-ui/core';
import {NavLink, NavLinkProps} from 'react-router-dom';
import {ProfileContext} from 'context/ProfileContext';

const useStyles = makeStyles(theme => ({
	listIcon: {
		minWidth: 30
	},
	menuItem: {
		textTransform: 'uppercase',
		letterSpacing: '1px',
		'& .MuiTypography-root': {
			fontWeight: 'normal',
			fontSize: 15,
			fontStyle: 'normal',
			[theme.breakpoints.down(1400)]: {
				letterSpacing: 'unset',
				fontSize: 14
			}
		},
		'&.active  .MuiTypography-root': {
			textDecoration: 'underline',
			fontWeight: 600
		},
		'&.active .MuiListItemIcon-root': {
			color: theme.palette.secondary.main
		},
		'& .MuiTouchRipple-root': {
			display: 'none'
		},
		'&:hover': {
			background: 'rgba(255,255,255,0.2)'
		},
		[theme.breakpoints.down(1200)]: {
			color: 'black !important'
		}
	}
}));

interface ListItemLinkProps {
	icon?: React.ReactElement;
	primary: React.ReactElement | string;
	to?: string;
	onClick?: (val: any) => void;
	disable?: boolean;
	disableForRoles?: string[];
	nav?: boolean;
}

const ListItemLink = (props: ListItemLinkProps) => {
	const {icon, primary, to, onClick, disable, disableForRoles, nav} = props;
	const {state: profileState} = useContext(ProfileContext);
	const classes = useStyles();

	const renderLink = useMemo(
		() =>
			forwardRef<any, Omit<NavLinkProps, 'to'>>((itemProps, ref) =>
				to ? <NavLink to={to} ref={ref} {...itemProps} onClick={onClick} /> : null
			),
		[to, onClick]
	);

	return (
		<li style={{cursor: 'pointer'}}>
			<ListItem
				button
				{...(to ? {component: renderLink} : {})}
				className={classes.menuItem}
				style={{
					color: nav ? '#fff' : '',
					opacity: primary === 'Reservations' || primary === 'Reports' ? 1 : ''
				}}
				disabled={disable || disableForRoles?.includes(profileState.role)}>
				{icon ? <ListItemIcon className={classes.listIcon}>{icon}</ListItemIcon> : null}
				<ListItemText primary={primary} />
			</ListItem>
		</li>
	);
};

export default ListItemLink;
