import {useState} from 'react';
import {
	Popover,
	ButtonProps,
	Tooltip,
	IconButton,
	Button,
	makeStyles,
	useTheme,
	useMediaQuery
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles(theme => ({
	popover: {
		'& li': {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'baseline'
		}
	},
	btn: {
		textTransform: 'none',
		background: 'white',
		color: 'black',
		padding: '5px 10px',
		alignItems: 'center',
		'&:hover': {
			background: 'white'
		},
		[theme.breakpoints.down(500)]: {
			maxWidth: 107
		}
	},
	showMoreIcon: {
		cursor: 'pointer',
		marginLeft: 5,
		color: theme.palette.secondary.main
	},
	spanPop: {
		boxSizing: 'border-box',
		[theme.breakpoints.down('sm')]: {
			maxWidth: 90,
			width: 90
		}
	}
}));

interface PopoverComponentProps {
	title: string;
	children: any;
	buttonColor?: ButtonProps['color'];
	btnText?: boolean;
	showDotes?: boolean;
	icon?: any;
	strLength?: number;
	path?: string;
	type?: string;
	iconButtonStyles?: any;
}

const PopoverComponent = ({
	title,
	buttonColor = 'inherit',
	btnText = false,
	showDotes = false,
	children,
	icon,
	strLength = 20,
	path,
	type,
	iconButtonStyles = {}
}: PopoverComponentProps) => {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const theme = useTheme();

	const isMobile = useMediaQuery(theme.breakpoints.down(1264));

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (event: any) => {
		event.stopPropagation();
		setAnchorEl(null);
	};

	return (
		<>
			{showDotes ? (
				<>
					<span onClick={handleClick}>
						{title?.length > strLength ? title.substring(0, strLength) : title}
					</span>
					{title?.length > strLength && (
						<span className={classes.showMoreIcon} onClick={handleClick}>
							...
						</span>
					)}
				</>
			) : btnText ? (
				<Button
					className={classes.btn}
					variant="text"
					color="secondary"
					size="small"
					onClick={handleClick}>
					{title === 'Cancelation Policy' && (
						<InfoIcon
							fontSize="medium"
							style={{
								marginRight:
									isMobile && title === 'Cancelation Policy' && type === 'cancellPolicy'
										? 'unset'
										: 5
							}}
						/>
					)}

					{isMobile && title === 'Cancelation Policy' && type === 'cancellPolicy' ? '' : title}
				</Button>
			) : (
				<Tooltip title={title}>
					<IconButton
						color="inherit"
						onClick={handleClick}
						style={{height: 'max-content', ...iconButtonStyles}}>
						{icon ? icon : <img src={path} alt="icon" width="30" height="30px" />}
					</IconButton>
				</Tooltip>
			)}

			<Popover
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
				className={classes.popover}
				onClick={e => {
					type !== 'cancellPolicy' && handleClose(e);
					e.stopPropagation();
				}}>
				{children}
			</Popover>
		</>
	);
};

export default PopoverComponent;
