import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const axiosClient = axios.create({
	headers: {
		'Content-Type': 'application/json'
	}
});

axiosClient.interceptors.request.use(function (config) {
	const token = localStorage.getItem('token');
	if (token) {
		config.headers.Authorization = 'Bearer ' + token;
	}

	return config;
});

axiosClient.interceptors.response.use(
	response => response,
	error => {
		if (error.response) {
			// The request was made and the server responded with a status code
			// that falls out of the range of 2xx
			if (error.response.status === 401) {
				localStorage.clear();
				window.location.replace('/login');
			}
		} else if (error.request) {
			// The request was made but no response was received
			// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
			// http.ClientRequest in node.js
			console.error(error);
		} else {
			// Something happened in setting up the request that triggered an Error
			console.error('Error on request');
		}
		return Promise.reject(error.response.data);
	}
);

export default axiosClient;
