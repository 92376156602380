const SEND_TICKET_EMAIL = '/api/hotelOrders/voucher/mail/';
const SEND_INVOICE_EMAIL = '/invoiceSend.php';
const REGISTER_CONFIRM_EMAIL = '/confirmEmail';
const RESET_PASSWORD_EMAIL = '/forgotPasswordVerify';

const EMAIL_API = {
	SEND_TICKET_EMAIL,
	SEND_INVOICE_EMAIL,
	REGISTER_CONFIRM_EMAIL,
	RESET_PASSWORD_EMAIL
};

export default EMAIL_API;
