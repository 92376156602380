const ADMIN = 'Admin';
const TRUSTED_USER = 'user';
const STANDARD_USER = 'Standard User';

const roles = {
	ADMIN,
	TRUSTED_USER,
	STANDARD_USER
};

export default roles;
