import {makeStyles} from '@material-ui/styles';
import {useEffect, useRef, useState} from 'react';
import {useMediaQuery, useTheme} from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme: any) => ({
	slideshow: {
		margin: '0 auto',
		marginBottom: 50,
		overflow: 'hidden',
		width: '100%',
		height: 'max-content',
		maxWidth: 1320
	},

	slideshowSlider: {
		whiteSpace: 'nowrap',
		transition: 'ease 300ms'
	},

	slide: {
		display: 'inline-block',
		height: 'max-content',
		width: '100%',
		borderRadius: 5,
		[theme.breakpoints.down('xs')]: {
			height: 100
		}
	},

	/* Buttons */

	slideshowDots: {
		display: 'flex',
		justifyContent: 'center',
		height: 50,
		width: '100%',
		marginTop: 8,
		[theme.breakpoints.down('sm')]: {
			display: 'none'
		}
	},

	slideshowDot: {
		height: 10,
		width: 10,
		borderRadius: '50%',
		cursor: 'pointer',
		margin: '15px 10px 0px',
		backgroundColor: 'rgba(0, 0, 0, .3)'
	},
	dotActive: {
		backgroundColor: 'black'
	},
	title: {
		marginTop: 60,
		marginBottom: 25,
		height: 41,
		fontWeight: 500,
		fontSize: 27,
		color: 'black',
		[theme.breakpoints.down('md')]: {
			fontSize: 22,
			marginBottom: 1,
			marginTop: 10
		}
	}
}));

interface AutoSliderProps {
	items: string[];
	title?: string;
	showDotes?: boolean;
	height?: string;
	mobileHeight?: string;
	border?: boolean;
}

const AutoSlider = ({items, title, showDotes, height, mobileHeight, border}: AutoSliderProps) => {
	const classes = useStyles();
	const [index, setIndex] = useState(0);
	const timeoutRef = useRef<any>();
	const theme = useTheme();

	function resetTimeout() {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
	}

	useEffect(() => {
		resetTimeout();
		timeoutRef.current = setTimeout(
			() => setIndex(prevIndex => (prevIndex === items.length - 1 ? 0 : prevIndex + 1)),
			5000
		);

		return () => {
			resetTimeout();
		};
	}, [index]);

	const handleClick = (url: any) => {
		return window.open(url, '_blank');
	};

	const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

	return (
		<div
			className={classes.slideshow}
			style={{
				height: height,
				maxWidth: height ? 'unset' : 1320,
				borderBottom: border ? '1px solid #aaa' : 'unset'
			}}>
			{title && <p className={classes.title}>{title}</p>}
			<div
				className={classes.slideshowSlider}
				style={{transform: `translate3d(${-index * 100}%, 0, 0)`}}>
				{items.map((el: any, index: number) => {
					return (
						<img
							src={process.env.REACT_APP_API_URL + el.picture}
							key={index}
							className={classes.slide}
							width="100%"
							height={height}
							style={{
								height: isMobile && mobileHeight ? mobileHeight : height,
								maxHeight: height ? 'unset' : 150
							}}
							onClick={el.link ? () => handleClick(el.link) : () => console.log('no link')}
							alt=""
						/>
					);
				})}
			</div>

			{showDotes && (
				<div className={classes.slideshowDots}>
					{items.map((el: any, idx: number) => (
						<div
							key={idx}
							className={clsx(classes.slideshowDot, index === idx && classes.dotActive)}
							onClick={() => {
								setIndex(idx);
							}}></div>
					))}
				</div>
			)}
		</div>
	);
};

export default AutoSlider;
