import api from 'api';
import axios from 'axios';
import qs from 'qs';
import axiosClient from 'utils/axiosClient';

const APP_API = process.env.REACT_APP_API_URL;
const EMAIL_API = process.env.REACT_APP_API_URL + '/pdfmail';

/**
 * Ticket
 */
interface SendEmailTicketParams {
	id: number;
	// pnr: string;
	// address: string;
	email: string;
}

// const sendEmailTicket = async (data: SendEmailTicketParams) => {
// 	const response = await axiosClient({
// 		method: 'get',
// 		url: api.EMAIL.SEND_TICKET_EMAIL,
// 		params: {...data}
// 	});
// 	return response.data;
// };

const sendEmailTicket = async (params: SendEmailTicketParams) => {
	const response = await axiosClient.get(api.EMAIL.SEND_TICKET_EMAIL, {params});
	return response.data;
};

/**
 * Invoice
 */
interface SendEmailInvoiceParams {
	pnr?: string;
	guesName?: string;
	address: string;
	email: string;
}

const sendEmailInvoice = async (data: SendEmailInvoiceParams) => {
	const response = await axios({
		method: 'get',
		url: EMAIL_API + api.EMAIL.SEND_INVOICE_EMAIL,
		params: {data: {...data, url: APP_API}}
	});

	return response.data;
};

/**
 * Receipt
 */

interface RegistrationConfirmParams {
	veryficationToken: string;
	userEmail: string;
	url: string;
}

const sendEmailRegistrationConfirm = async (data: RegistrationConfirmParams) => {
	const response = await axios({
		method: 'post',
		url: EMAIL_API + api.EMAIL.REGISTER_CONFIRM_EMAIL,
		data: qs.stringify(data)
	});

	return response.data;
};

interface ResetPasswordParams {
	verificationURL: string;
	userEmail: string;
	url: string;
}

const sendEmailResetPassword = async (data: ResetPasswordParams) => {
	const response = await axios({
		method: 'post',
		url: EMAIL_API + api.EMAIL.RESET_PASSWORD_EMAIL,
		data: qs.stringify(data)
	});

	return response.data;
};

const verificateEmail = async (url: string, userId: string) => {
	const response = await axios({
		method: 'get',
		url: url + '&userId=' + userId
	});
	return response.data;
};

const service = {
	sendEmailTicket,
	sendEmailInvoice,
	sendEmailRegistrationConfirm,
	sendEmailResetPassword,
	verificateEmail
};

export default service;
