import {makeStyles, Grid, Button, Input} from '@material-ui/core';
import {NavLink} from 'react-router-dom';
import logoFooter from 'assets/logoFooter.png';
import {Social} from 'components/AppBar/AppBar';
import {useState, useContext} from 'react';
import {AuthContext} from 'context/AuthContext';

const useStyles = makeStyles(theme => ({
	container: {
		display: 'flex',
		color: 'black',
		background: '#f5f1fa',
		justifyContent: 'center',
		'& a': {
			fontWeight: 400,
			fontSize: 16,
			color: '#7D8494',
			marginBottom: 8,
			textDecoration: 'none'
		},
		[theme.breakpoints.down('sm')]: {
			backgroundColor: '#F9F9F9'
		}
	},
	leftFooter: {
		position: 'relative',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		'& .MuiGrid-container': {
			position: 'relative'
		}
	},
	leftImage: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		[theme.breakpoints.down('sm')]: {
			display: 'none'
		}
	},
	infoContainer: {
		marginTop: 40,
		marginLeft: 50,
		[theme.breakpoints.down('sm')]: {padding: '30px 0 10px 0px', marginLeft: 30}
	},
	logo: {
		width: 250,
		[theme.breakpoints.down('sm')]: {marginBottom: 20}
	},
	contacts: {
		display: 'flex',
		flexDirection: 'column',
		marginTop: 20,
		color: '#7D8494',
		lineHeight: 2,
		marginBottom: 20
	},
	phoneNumber: {
		width: 'fit-content'
	},
	links: {
		paddingLeft: 60,
		[theme.breakpoints.down('sm')]: {
			padding: '0px 60px 30px 0px'
		}
	},
	social: {
		display: 'flex',
		flexWrap: 'wrap',
		zIndex: 0,
		width: 180,
		marginTop: 20,
		[theme.breakpoints.down('sm')]: {paddingBottom: 40}
	},
	p: {
		fontSize: 14,
		fontWeight: 'normal',
		bottom: 12,
		paddingTop: 15,
		position: 'absolute',
		left: 59,
		color: 'white',
		[theme.breakpoints.down('sm')]: {left: 20}
	},
	rightFooter: {
		zIndex: 1,
		'& img': {
			height: '100%',
			marginLeft: -45
		},
		[theme.breakpoints.down('sm')]: {
			display: 'none'
		}
	},
	pages: {
		display: 'flex',
		flexDirection: 'column',
		marginRight: 60,
		[theme.breakpoints.down('sm')]: {
			marginBottom: 50
		}
	},
	title: {
		fontSize: 18,
		fontWeight: 600,
		marginBottom: 20,
		width: '30%',
		color: '#3f489d'
	},
	subscribeBlock: {
		marginTop: 20,
		display: 'flex',
		flexDirection: 'column',
		width: '80%',
		[theme.breakpoints.down('sm')]: {
			width: '90%'
		}
	},
	flexColum: {
		[theme.breakpoints.down('xs')]: {
			display: 'flex',
			flexDirection: 'column'
		}
	},
	subscribeInput: {
		width: '60%',
		background: 'white',
		padding: '6px 10px',
		[theme.breakpoints.down('xs')]: {
			width: '100%'
		}
	},
	subscribeBtn: {
		backgroundColor: '#3f489d',
		color: 'white',
		letterSpacing: 1,
		padding: '10px 10px',
		marginLeft: 20,
		width: '30%',
		'&:hover': {
			backgroundColor: '#3f489d'
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			marginLeft: 'unset',
			marginTop: 10
		}
	},
	onHover: {
		'&:hover': {
			textDecoration: 'underline'
		}
	},
	errorMessage: {
		fontSize: 14,
		color: 'red'
	},
	successMessage: {
		fontSize: 14,
		color: '#4BB543'
	}
}));

const ContactUs = () => {
	const classes = useStyles();
	const [email, setEmail] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const {state: authState} = useContext(AuthContext);
	const [success, setSuccess] = useState('');
	const regEx = new RegExp(
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	);
	// const EmailValidation = () => {
	// 	const regEx = /[a-zA-Z0-9._%+-+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
	// 	if (regEx.test(email)) setMessage('');
	// 	else if (!regEx.test(email) && email !== '') setMessage('Email is not valid');
	// 	else {
	// 		setMessage('');
	// 	}
	// };
	const onSubmit = () => {
		if (email) {
			if (!regEx.test(email)) {
				setErrorMessage('Wrong format!');
				return;
			}
			setErrorMessage('');
			setSuccess('The email was successfully sent!');
		} else {
			setSuccess('');
			setErrorMessage('The Email is required!');
		}
		setEmail('');
		setTimeout(() => setSuccess(''), 5000);
	};
	const handleChange = (evt: any) => {
		setEmail(evt.target.value);
	};

	const pages1 = [
		{label: 'Why book with us', link: '/whyBookWithUs'},
		{label: 'Terms and conditions', link: 'customer-agreement.pdf', target: '_blank'},
		{label: 'Visa assistance', link: ''},
		{label: 'Tours and excursions', link: ''},
		{label: 'About Us', link: '/aboutUs'}
	];
	const pages2 = [
		{label: 'MICE', link: ''},
		{label: 'Ground handling', link: ''},
		{label: 'Contact Us', link: '/contuctUs'},
		{label: 'Login', link: ''}
	];

	return (
		<div className={classes.container} style={{zIndex: 1}}>
			<Grid container className={classes.infoContainer}>
				<Grid item xs={12} sm={6} lg={4}>
					<NavLink to="/">
						<img src={logoFooter} alt={'Logo'} className={classes.logo} />
					</NavLink>
					<div className={classes.social}>
						<Social />
					</div>
				</Grid>
				<Grid item xs={12} sm={6} lg={4}>
					<p className={classes.title}>Pages</p>
					<div style={{display: 'flex'}}>
						<div className={classes.pages}>
							{pages1.map((el: {label: string; link: string; target?: string}) => (
								<NavLink
									to={el.link}
									target={el.target ? el.target : '_parent'}
									className={classes.onHover}
									key={el.label}>
									{el.label}
								</NavLink>
							))}
						</div>
						<div className={classes.pages}>
							{pages2.map((el: {label: string; link: string}) => (
								<NavLink to={el.link} className={classes.onHover} key={el.label}>
									{el.label}
								</NavLink>
							))}
						</div>
					</div>
				</Grid>

				<Grid item xs={12} sm={6} lg={4}>
					<p className={classes.title}>Subscribe</p>

					<div className={classes.subscribeBlock}>
						<p style={{color: '#777', marginBottom: 20}}>
							Get the best deals in your inbox. Sign up for our newsletter.
						</p>
						<div className={classes.flexColum}>
							<Input
								placeholder="Enter your email"
								type="email"
								className={classes.subscribeInput}
								value={email}
								onChange={handleChange}
							/>
							<Button className={classes.subscribeBtn} onClick={onSubmit}>
								Subscribe
							</Button>
							<p className={classes.errorMessage}>{errorMessage}</p>
							<p className={classes.successMessage}>{success}</p>
						</div>
					</div>
				</Grid>
			</Grid>
		</div>
	);
};

export default ContactUs;
